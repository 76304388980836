.highlight {
  font-size: 15px;
  color: white;
  background-color: #007acc;
  border-radius: 8px;
  /* flex: 0 0 12% !important; */
}

.normal {
  border-radius: 8px;
}

.react-calendar {
  border: 0px;
}

.react-calendar__month-view__weekdays {
  text-transform: none !important;
}

abbr[title] {
  text-decoration: none;
}